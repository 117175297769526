import { createObserver } from "./createObserver";

// observer: %20 si gorundugunde "intersect" olsun
const iframeLazyLoadObserver = createObserver(0.2);

document.addEventListener("DOMContentLoaded", () => {
  const iframe_lazy_loads = document.querySelectorAll(".iframe-lazy-load");
  iframe_lazy_loads.forEach((iframe) => {
    //observeri sayfadaki her bir '.iframe-lazy-load' a bagla.
    iframeLazyLoadObserver.observe(iframe);

    iframe.addEventListener(
      "intersecting",
      () => {
        if (iframe.classList.contains("iframe-lazy-load--loaded")) {
          return;
        }
        iframe.setAttribute("src", iframe.getAttribute("data-src"));
        iframe.classList.add("iframe-lazy-load--loaded");
      },
      { once: true },
    );
  });
});
