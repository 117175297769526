import { createObserver } from "./createObserver";
import "./iframe-lazy-load";

document.addEventListener("DOMContentLoaded", () => {
  const /** @type HTMLVideoElement */ loop_video =
      document.getElementById("loop-video");
  const breakpoint_md = window.matchMedia("(min-width: 768px)");
  const loopVideos = document.querySelectorAll("video[loop]");
  let observer = createObserver(0.5);
  const mediaQueries = [
    {
      mq: "(min-width:1400px)",
      src: "data-src-horizontal-1080p",
    },
    {
      mq: "(min-width:1200px)",
      src: "data-src-horizontal-720p",
    },
    {
      mq: "(min-width:768px)",
      src: "data-src-horizontal-540p",
    },
    {
      mq: "(min-width: 650px)",
      src: "data-src-vertical-1080p",
    },
    {
      mq: "(min-width: 450px)",
      src: "data-src-vertical-720p",
    },
    {
      mq: "screen",
      src: "data-src-vertical-540p",
    },
  ];
  function breakpoint_check() {
    video_src_check(loop_video, true);
  }
  breakpoint_check();

  function video_src_check(
    /** @type HTMLVideoElement */ video_element,
    /** @type bool */ skipIfEmpty,
  ) {
    if (skipIfEmpty && !video_element.hasAttribute("src")) {
      return;
    }
    for (const mq_src of mediaQueries) {
      if (window.matchMedia(mq_src.mq).matches) {
        if (
          !video_element.hasAttribute("src") ||
          video_element.src !== video_element.getAttribute(mq_src.src)
        ) {
          video_element.setAttribute(
            "src",
            video_element.getAttribute(mq_src.src),
          );
        }
        break;
      }
    }
  }

  breakpoint_md.addEventListener("change", breakpoint_check);

  loopVideos.forEach((loopVideo) => {
    observer.observe(loopVideo);
    loopVideo.addEventListener("intersecting", () => {
      video_src_check(loopVideo, false);
      loopVideo.play();
    });
    loopVideo.addEventListener("not-intersecting", () => {
      loopVideo.pause();
    });
  });
});
